import * as React from "react"
import Nav from "../components/Nav"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet";
import duckGif from "../images/vibing-duck.gif"


const NotFoundPage  = () => {
  
  return(
  <div>
    <Helmet title="Maitais At Sunset" />
    <Nav />
    <div>
        <div className="bg-gray-100 min-h-screen flex justify-center items-center">
            <div className="px-10 py-10 bg-gray-50 rounded-xl shadow-xl">
                <img src={duckGif} alt="Vibing duck." />
                <p className="text-center pt-10 font-mono">Oops! No page here. Just vibes!</p>
            </div>
        </div>
    </div>
    <Footer />

  </div>
)}

export default NotFoundPage
